<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" >
        <v-card v-if="dialog" class="new-mm">
            <v-toolbar dark color="primary" height="48" fixed>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Message</v-toolbar-title>
            </v-toolbar>
            <v-container class="panel-wrapper">
                <v-layout column style="margin-top: 48px">
                    <v-flex class="my-3">
                        <editor ref="editor"></editor>
                    </v-flex>
                    <v-flex class="my-3">
                        <Upload :size="limitSize.size" ref="messageupload" v-model="fileList">
                            <template v-slot:files="{files}">
                                <FileList @remove = 'removeFile' :files="files"></FileList>
                            </template>
                            <v-btn class="ml-0" dark color="blue-grey">Attach files</v-btn>
                        </Upload>
                    </v-flex>
                    <v-flex>
                        <v-layout row>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" flat @click="dialog=false">cancel</v-btn>
                            <v-btn color="primary" :loading="loading" @click="ok">send
                                <v-icon right dark>send</v-icon>
                            </v-btn>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
    import Upload from "@/components/upload/Upload"
    import FileList from '@/components/upload/FileList.vue'
    import editor from '@/components/Editor.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import {limitSize} from '@/module/constant.js'


    export default {
        name: "TicketMessage",
        components: {
            Upload,FileList,editor
        },
        mixins: [loadingMixin],
        data (){
            return {
                limitSize: limitSize,
                fileList: [],
                formData: {
                    attachments: [],
                    content: ''
                },
            }
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.fileList = []
                    this.$refs.editor.setData('')
                }
            }
        },
        methods: {
            removeFile(index) {
                this.fileList.splice(index,1)
            },
            createTicketData() {
                this.formData = Object.assign(this.formData, {
                    content: this.$refs.editor.getData(),
                    attachments: this.fileList.map( o => o.response.data)
                })
            },
            $validator() {
                this.createTicketData()
                return this.$refs.messageupload.getUploaded()
            }
        }
    }
</script>

<style lang="scss">
    .new-mm{
        .file-uploads-html5{
            text-align: left;
        }
    }

</style>
