<template>
    <v-container class="px-0 ticket-wrapper" :class="{'ticket-wrapper': !!!ticketDetail.client }">
        <v-layout>
            <v-flex style="text-align: center;" shrink>
                <div style="background: #f1f6f6;text-align: center;padding-top: 10px;width: 80px;height: 80px;border-radius: 3px;">
                    <v-img :src="ticketDetail.is_from_client?clientImg : supportImg" style="display: inline-block;width: 60px;height: 60px;"></v-img>
                </div>
            </v-flex>
            <v-flex class="pl-3">
                <p><i v-if="ticketDetail.type === 'support'" style="font-style: normal">Support: </i>{{ticketDetail.from_address}}&nbsp;&nbsp;&nbsp;<span v-if="ticketDetail.cc_addresses">CC:</span> {{ticketDetail.cc_addresses}}</p>
                <p>{{formatDate(ticketDetail.received_date,'MM/DD/YYYY HH:mm')}}</p>
                <p style="font-weight: bold;">{{ticketDetail.subject}}</p>
                <p v-html="ticketDetail.body_html" style="margin-top: 15px;"></p>
                <div  v-if="ticketDetail.attachments.length" class="mt-3">Files:</div>
                <template v-for="(item,i) in ticketDetail.attachments">
                    <a class="mt-1" style="display: block" :key="i" :href="item.file" :download="item.filename">{{item.filename}}</a>
                </template>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {formatDate} from '@/module/utils/date'
    import supportImg  from './support.png'
    import clientImg  from './client.png'
    export default {
        name: "TicketCard",
        data (){
            return {
                supportImg,
                clientImg,
            }
        },
        props: ['ticketDetail'],
        methods: {
            formatDate
        }
    }
</script>

<style lang="scss">
    .ticket-wrapper {
        p{margin-bottom: 5px;}
        pre{white-space: normal;}
    }
</style>
