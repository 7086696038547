var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "px-0 ticket-wrapper",
      class: { "ticket-wrapper": !!!_vm.ticketDetail.client }
    },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { staticStyle: { "text-align": "center" }, attrs: { shrink: "" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#f1f6f6",
                    "text-align": "center",
                    "padding-top": "10px",
                    width: "80px",
                    height: "80px",
                    "border-radius": "3px"
                  }
                },
                [
                  _c("v-img", {
                    staticStyle: {
                      display: "inline-block",
                      width: "60px",
                      height: "60px"
                    },
                    attrs: {
                      src: _vm.ticketDetail.is_from_client
                        ? _vm.clientImg
                        : _vm.supportImg
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "v-flex",
            { staticClass: "pl-3" },
            [
              _c("p", [
                _vm.ticketDetail.type === "support"
                  ? _c("i", { staticStyle: { "font-style": "normal" } }, [
                      _vm._v("Support: ")
                    ])
                  : _vm._e(),
                _vm._v(_vm._s(_vm.ticketDetail.from_address) + "   "),
                _vm.ticketDetail.cc_addresses
                  ? _c("span", [_vm._v("CC:")])
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.ticketDetail.cc_addresses))
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.formatDate(
                      _vm.ticketDetail.received_date,
                      "MM/DD/YYYY HH:mm"
                    )
                  )
                )
              ]),
              _c("p", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.ticketDetail.subject))
              ]),
              _c("p", {
                staticStyle: { "margin-top": "15px" },
                domProps: { innerHTML: _vm._s(_vm.ticketDetail.body_html) }
              }),
              _vm.ticketDetail.attachments.length
                ? _c("div", { staticClass: "mt-3" }, [_vm._v("Files:")])
                : _vm._e(),
              _vm._l(_vm.ticketDetail.attachments, function(item, i) {
                return [
                  _c(
                    "a",
                    {
                      key: i,
                      staticClass: "mt-1",
                      staticStyle: { display: "block" },
                      attrs: { href: item.file, download: item.filename }
                    },
                    [_vm._v(_vm._s(item.filename))]
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }