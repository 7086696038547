var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "close-ticket",
      attrs: { width: "600" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "title" }, [
            _vm._v("Close Ticket")
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("v-select", {
                            attrs: {
                              rules: [_vm.rules.required],
                              label: "Reason",
                              items: [
                                "Issue is solved",
                                "Sent a wrong ticket",
                                "Has another ticket",
                                "Other"
                              ]
                            },
                            model: {
                              value: _vm.formData.reason,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "reason", $$v)
                              },
                              expression: "formData.reason"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "other-reason",
                          class: { active: _vm.formData.reason === "Other" }
                        },
                        [
                          _vm.formData.reason === "Other"
                            ? _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  placeholder: "Please specify below.",
                                  rules: [_vm.rules.required]
                                },
                                model: {
                                  value: _vm.formData.other,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "other", $$v)
                                  },
                                  expression: "formData.other"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { color: "blue darken-1", flat: "" },
                  on: { click: _vm.close }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { color: "primary", loading: _vm.loading },
                  on: { click: _vm.ok }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }