<template>
    <v-container class="panel-wrapper mt-4" style="padding-bottom: 72px" v-if="ready">
        <v-layout column>
            <v-flex>
                <v-btn class="ma-0" @click="$router.push('/ticket')" icon>
                    <i class="fa fa-angle-left" style="font-size: 30px;color: rgba(0,0,0,.7);margin-right: 5px;"
                       aria-hidden="true"></i>
                </v-btn>
            </v-flex>
            <v-flex class="mt-4 mb-2">
                <v-subheader class="px-0">
                    <v-layout wrap>
                        <v-flex md4 xs12>
                            <h2>Ticket: <span :style="{color:$vuetify.theme.primary}">{{details.number}}</span><span
                                    v-if="details.status==='Closed'"> (Closed)</span></h2>
                        </v-flex>
                        <v-flex md7 xs8 align-self-center >
                            <span class="small-title" style="color: rgba(0,0,0,.87);font-weight: bold;font-size: 14px;line-height: 32px;">{{details.department?details.department.name:''}}</span>
                        </v-flex>
                        <v-flex md1 xs4  shrink align-self-center v-if="details.status==='Closed'">
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <i  v-on="on" :style="{color: $vuetify.theme.accent,'cursor':'pointer'}" class="fa fa-envelope ticket-icon" aria-hidden="true"></i>
                                </template>
                                <span>Closed: {{details.close_reason}}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-subheader>
                <div>
                </div>
            </v-flex>
            <v-flex class="my-2">
                <template v-for="(message ,i) in details.messages">
                    <v-divider :key="i" v-if="i === 0"></v-divider>
                    <ticketCard :ticketDetail="message" :key="i"></ticketCard>
                    <v-divider :key="i" v-if="i!==(details.messages.length)"></v-divider>
                </template>
            </v-flex>
        </v-layout>
        <v-footer fixed height="auto" style="background-color: #fafafa">
            <v-container style="background-color: #ffffff" class="pa-0">
                <v-btn color="primary" :disabled="details.status==='Closed'" @click="$refs.message.open()"
                       style="margin-left: 24px">Reply
                </v-btn>
                <v-btn class="ma-0" v-if="details.status!=='Closed'" color="blue darken-1" flat
                       @click="$refs.closeticket.open()">
                    Close
                </v-btn>
            </v-container>
        </v-footer>
        <NewTicket ref="newticket" @confirm="createTicket"></NewTicket>
        <TicketMessage ref="message" @confirm="updateTicket"></TicketMessage>
        <CloseTicket ref="closeticket" @confirm="closeTicket"></CloseTicket>
    </v-container>
</template>

<script>
    import ticketCard from './_source/TicketCard.vue'
    import NewTicket from './_source/NewTicket.vue'
    import TicketMessage from './_source/Message.vue'
    import CloseTicket from './_source/CloseTicket.vue'

    import {mapActions} from 'vuex'

    export default {
        name: "TicketDetails",
        components: {
            ticketCard,
            NewTicket,
            TicketMessage,
            CloseTicket
        },
        data() {
            return {
                ready: false,
                details: {},
            }
        },
        watch: {
            '$route': function ($route) {
                this.tid = $route.params.tid
                this.getDetail()
            }
        },
        methods: {
            getDetail() {
                this.$http(`/tickets/${this.tid}/`).then(res => {
                    this.details = res
                    this.ready = true
                })
            },
            createTicket(formData) {
                return this.sendTicket(formData).then((res) => {
                    this.$message.success('A new ticket was created')
                    this.$nextTick(() => {
                        this.$router.push(`/ticket/${res.id}`)
                    })
                    return Promise.resolve(res)
                }).catch((e) => {
                    this.$message.error('Oops, you failed to create a new ticket. You can reach us at support@cloudclusters.io for this issue.')
                    return Promise.reject(e)
                })
            },
            closeTicket({reason, other}) {
                return this.$http.put(`/tickets/${this.tid}/close/`, {
                    close_reason: reason === 'Other' ? other : reason,
                }).then(() => {
                    this.$message.success(`Your ticket ${this.details.number} has been closed.`)
                    this.$router.push('/ticket')
                }).catch((e) => {
                    this.$message.error('Oops, you failed to reply to the ticket. You can reach us via email: support@cloudclusters.io')
                    return Promise.reject(e)
                })
            },
            updateTicket({attachments, content}) {
                return this.$http.post(`/tickets/${this.tid}/reply/`, {
                    department: this.details.department.id,
                    subject: this.details.subject,
                    attachments: attachments,
                    content: content,
                }).then(() => {
                    this.getDetail()
                }).catch((e) => {
                    this.$message.error('Oops, you failed to reply to the ticket. You can reach us via email: support@cloudclusters.io')
                    return Promise.reject(e)
                })
            },
            ...mapActions('ticket', ['sendTicket']),
        },
        created() {
            this.tid = this.$route.params.tid
            this.getDetail()
            this.$SDK.track({
                pageName : 'My_tickets_detail'
            })
        }
    }
</script>

<style lang="scss">

</style>
