var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "v-container",
        {
          staticClass: "panel-wrapper mt-4",
          staticStyle: { "padding-bottom": "72px" }
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-0",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/ticket")
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-angle-left",
                        staticStyle: {
                          "font-size": "30px",
                          color: "rgba(0,0,0,.7)",
                          "margin-right": "5px"
                        },
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "mt-4 mb-2" },
                [
                  _c(
                    "v-subheader",
                    { staticClass: "px-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { md4: "", xs12: "" } }, [
                            _c("h2", [
                              _vm._v("Ticket: "),
                              _c(
                                "span",
                                {
                                  style: { color: _vm.$vuetify.theme.primary }
                                },
                                [_vm._v(_vm._s(_vm.details.number))]
                              ),
                              _vm.details.status === "Closed"
                                ? _c("span", [_vm._v(" (Closed)")])
                                : _vm._e()
                            ])
                          ]),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                md7: "",
                                xs8: "",
                                "align-self-center": ""
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "small-title",
                                  staticStyle: {
                                    color: "rgba(0,0,0,.87)",
                                    "font-weight": "bold",
                                    "font-size": "14px",
                                    "line-height": "32px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.details.department
                                        ? _vm.details.department.name
                                        : ""
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm.details.status === "Closed"
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    md1: "",
                                    xs4: "",
                                    shrink: "",
                                    "align-self-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "i",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "fa fa-envelope ticket-icon",
                                                      style: {
                                                        color:
                                                          _vm.$vuetify.theme
                                                            .accent,
                                                        cursor: "pointer"
                                                      },
                                                      attrs: {
                                                        "aria-hidden": "true"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2950713745
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Closed: " +
                                            _vm._s(_vm.details.close_reason)
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div")
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "my-2" },
                [
                  _vm._l(_vm.details.messages, function(message, i) {
                    return [
                      i === 0 ? _c("v-divider", { key: i }) : _vm._e(),
                      _c("ticketCard", {
                        key: i,
                        attrs: { ticketDetail: message }
                      }),
                      i !== _vm.details.messages.length
                        ? _c("v-divider", { key: i })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-footer",
            {
              staticStyle: { "background-color": "#fafafa" },
              attrs: { fixed: "", height: "auto" }
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "pa-0",
                  staticStyle: { "background-color": "#ffffff" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "margin-left": "24px" },
                      attrs: {
                        color: "primary",
                        disabled: _vm.details.status === "Closed"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.message.open()
                        }
                      }
                    },
                    [_vm._v("Reply\n            ")]
                  ),
                  _vm.details.status !== "Closed"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-0",
                          attrs: { color: "blue darken-1", flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.closeticket.open()
                            }
                          }
                        },
                        [_vm._v("\n                Close\n            ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("NewTicket", {
            ref: "newticket",
            on: { confirm: _vm.createTicket }
          }),
          _c("TicketMessage", {
            ref: "message",
            on: { confirm: _vm.updateTicket }
          }),
          _c("CloseTicket", {
            ref: "closeticket",
            on: { confirm: _vm.closeTicket }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }