var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm.dialog
        ? _c(
            "v-card",
            { staticClass: "new-mm" },
            [
              _c(
                "v-toolbar",
                {
                  attrs: { dark: "", color: "primary", height: "48", fixed: "" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Message")])
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "panel-wrapper" },
                [
                  _c(
                    "v-layout",
                    {
                      staticStyle: { "margin-top": "48px" },
                      attrs: { column: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "my-3" },
                        [_c("editor", { ref: "editor" })],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "my-3" },
                        [
                          _c(
                            "Upload",
                            {
                              ref: "messageupload",
                              attrs: { size: _vm.limitSize.size },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "files",
                                    fn: function(ref) {
                                      var files = ref.files
                                      return [
                                        _c("FileList", {
                                          attrs: { files: files },
                                          on: { remove: _vm.removeFile }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1637571175
                              ),
                              model: {
                                value: _vm.fileList,
                                callback: function($$v) {
                                  _vm.fileList = $$v
                                },
                                expression: "fileList"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-0",
                                  attrs: { dark: "", color: "blue-grey" }
                                },
                                [_vm._v("Attach files")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", flat: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog = false
                                    }
                                  }
                                },
                                [_vm._v("cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.ok }
                                },
                                [
                                  _vm._v("send\n                            "),
                                  _c(
                                    "v-icon",
                                    { attrs: { right: "", dark: "" } },
                                    [_vm._v("send")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }