<template>
    <v-dialog v-model="dialog" width="600" class="close-ticket">
        <v-card>
            <v-card-title class="title">Close Ticket</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form">
                    <v-layout column>
                        <v-flex>
                            <v-select v-model="formData.reason"
                                      :rules="[rules.required]"
                                      label="Reason"
                                      :items="['Issue is solved','Sent a wrong ticket','Has another ticket','Other']">
                            </v-select>
                        </v-flex>
                        <v-flex :class="{active:formData.reason === 'Other'}"  class="other-reason">
                            <v-textarea v-if="formData.reason ==='Other'"
                                        outlined
                                        placeholder="Please specify below."
                                        v-model="formData.other"
                                        :rules="[rules.required]">
                            </v-textarea>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="text-none" color="blue darken-1" flat @click="close">Cancel</v-btn>
                <v-btn class="text-none" color="primary" :loading="loading" @click="ok">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'


    export default {
        name: "CloseTicket",
        mixins: [loadingMixin],
        data() {
            return {
                formData: {
                    reason: '',
                    other: ''
                },
                rules
            }
        },
        watch: {
            dialog() {
                if (!this.dialog) {
                    this.formData.reason = ''
                    this.formData.other = ''
                    this.$refs.form.reset()
                }
            }
        },
        methods: {
            $validator() {
                return this.$refs.form.validate()
            }
        }
    }
</script>

<style lang="scss">
        .other-reason {
            height: 0;
            opacity: 0;
            transition: all ease .3s;
            &.active {
                height: 140px;
                opacity: 1;
            }
        }
</style>